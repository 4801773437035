import * as wasm from './wasm_thjread_bg.wasm';

function _assertClass(instance, klass) {
    if (!(instance instanceof klass)) {
        throw new Error(`expected instance of ${klass.name}`);
    }
    return instance.ptr;
}
/**
* @param {Vec2d} p
* @returns {InTextResult}
*/
export function in_text(p) {
    _assertClass(p, Vec2d);
    const ptr0 = p.ptr;
    p.ptr = 0;
    const ret = wasm.in_text(ptr0);
    return InTextResult.__wrap(ret);
}

let cachedTextDecoder = new TextDecoder('utf-8', { ignoreBOM: true, fatal: true });

let cachegetUint8Memory = null;
function getUint8Memory() {
    if (cachegetUint8Memory === null || cachegetUint8Memory.buffer !== wasm.memory.buffer) {
        cachegetUint8Memory = new Uint8Array(wasm.memory.buffer);
    }
    return cachegetUint8Memory;
}

function getStringFromWasm(ptr, len) {
    return cachedTextDecoder.decode(getUint8Memory().subarray(ptr, ptr + len));
}
/**
*/
export class InTextResult {

    static __wrap(ptr) {
        const obj = Object.create(InTextResult.prototype);
        obj.ptr = ptr;

        return obj;
    }

    free() {
        const ptr = this.ptr;
        this.ptr = 0;

        wasm.__wbg_intextresult_free(ptr);
    }
    /**
    * @returns {boolean}
    */
    get_hit() {
        const ret = wasm.intextresult_get_hit(this.ptr);
        return ret !== 0;
    }
    /**
    * @returns {number}
    */
    get_dist() {
        const ret = wasm.intextresult_get_dist(this.ptr);
        return ret;
    }
}
/**
*/
export class Vec2d {

    static __wrap(ptr) {
        const obj = Object.create(Vec2d.prototype);
        obj.ptr = ptr;

        return obj;
    }

    free() {
        const ptr = this.ptr;
        this.ptr = 0;

        wasm.__wbg_vec2d_free(ptr);
    }
    /**
    * @param {number} x
    * @param {number} y
    * @returns {Vec2d}
    */
    constructor(x, y) {
        const ret = wasm.vec2d_new(x, y);
        return Vec2d.__wrap(ret);
    }
}

export const __wbindgen_throw = function(arg0, arg1) {
    throw new Error(getStringFromWasm(arg0, arg1));
};

